import React from 'react';
import { graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { events } from '../utils/trackEvent';
import config from '../config';
import { DisplayTiny, Medium, Small, HeaderSmall } from '../components/text';
import { useUser } from '../utils/useUser';
import { navColorOptions } from '../components/navbar/navColors';
import { navSections } from '../components/navbar';
import Layout from '../components/layout';
import { Button, buttonVariants } from '../components/Button';
import SEO from '../components/seo';
import { usePageMetaAttributes } from '../utils/usePageMetaAttributes';
import { StyledComponents, StyledTwitterLogo } from '../components/ReferralsLandingPage';

export const referralsLandingPageQuery = graphql`
  query ReferralsLandingPageQuery {
    copy: contentfulReferralsLandingPage {
      title
      linkTweetTemplate
      thePerksTitle
      perk1Title
      perk1Subtitle
      perk1Description {
        perk1Description
      }
      howItWorksTitle
      howItWorksSteps {
        json
      }
      nameGiftingNote
      theFactsTitle
      theFacts {
        json
      }
      factTweetTemplate
      linkFactTweetTemplate
      disclaimer {
        disclaimer
      }
    }
  }
`;

const anchorProps = { target: '_blank', rel: 'noopener noreferrer' };
const twitterPrefix = 'https://twitter.com/intent/tweet?text=';
const tweetButtonColors = ['#D1D461', '#EACD4C', '#FFC73A', '#FFC73A'];

const ReferralsLandingPage = ({ data: { seo, copy } }) => {
  const meta = usePageMetaAttributes('earn');
  const { linkTweetTemplate, factTweetTemplate, linkFactTweetTemplate } = copy;

  const user = useUser();
  const [copied, setCopied] = React.useState(false);

  const userIsLoading = user === null;
  const userIsLoggedOut = !userIsLoading && !user.hasOwnProperty('referralCode');
  const userReferralCode =
    !userIsLoading && !userIsLoggedOut ? `${config.rootUrl}/register/${user.referralCode}` : '';

  const makeTweet = (template, replacements) => {
    return encodeURIComponent(
      Object.keys(replacements).reduce((tweet, key) => {
        return tweet.replace(key, replacements[key]);
      }, template)
    );
  };

  const copyCode = () => {
    if (!navigator.clipboard) return;
    navigator.clipboard.writeText(userReferralCode).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1500);
    });
    events.referrals.copyCode.send();
  };

  const extractFacts = () => {
    const rootContent = copy.theFacts.json.content;
    const listContent = rootContent.find(content => content.nodeType === 'unordered-list');
    return listContent.content.map(factDocument => factDocument.content[0].content[0].value);
  };

  return (
    <Layout navColor={navColorOptions.void} currentSection={navSections.EARN}>
      <SEO {...meta} />
      <StyledComponents.HeroLayout>
        <StyledComponents.HeroCopy as="h1" align="center" colored="white">
          {copy.title}
        </StyledComponents.HeroCopy>
        {userIsLoggedOut ? (
          <Button as="a" href={`${config.rootUrl}/login`} variant={buttonVariants.proBrand}>
            Log in
          </Button>
        ) : (
          <StyledComponents.HeroCodeAndButton>
            <StyledComponents.ReferralCodeField>
              <input value={userReferralCode} readOnly aria-label="Your referral code" />
              <Small
                as="button"
                role={copied ? 'alert' : 'button'}
                weight="medium"
                colored="green"
                onClick={copyCode}>
                {copied ? 'Copied!' : 'Copy'}
              </Small>
            </StyledComponents.ReferralCodeField>
            <Button
              {...anchorProps}
              as="a"
              href={`${twitterPrefix}${makeTweet(linkTweetTemplate, {
                ':code': userReferralCode,
              })}`}
              variant={buttonVariants.proBrand}
              onClick={() => events.referrals.tweetCode.send()}
              aria-label="Tweet referral code">
              <StyledTwitterLogo color="#000000" />
            </Button>
          </StyledComponents.HeroCodeAndButton>
        )}
      </StyledComponents.HeroLayout>
      <StyledComponents.Band />
      <StyledComponents.ThePerksLayout heading={copy.thePerksTitle}>
        <StyledComponents.PerkItemLayout>
          <DisplayTiny as="h2" align="center">
            {copy.perk1Title}
          </DisplayTiny>
          <Medium align="center">{copy.perk1Subtitle}</Medium>
          <Small align="center" colored="grey80">
            {copy.perk1Description.perk1Description}
          </Small>
        </StyledComponents.PerkItemLayout>
        <StyledComponents.PerkItemLayout>
          <DisplayTiny as="h2" align="center">
            {copy.howItWorksTitle}
          </DisplayTiny>
          <StyledComponents.HowItWorksSteps>
            {documentToReactComponents(copy.howItWorksSteps.json)}
          </StyledComponents.HowItWorksSteps>
        </StyledComponents.PerkItemLayout>
      </StyledComponents.ThePerksLayout>
      <StyledComponents.NameGiftingNote copy={copy.nameGiftingNote} />
      <StyledComponents.TheFactsLayout heading={copy.theFactsTitle}>
        {extractFacts().map((factText, i) => {
          const tweetTemplate =
            userIsLoading || userIsLoggedOut ? factTweetTemplate : linkFactTweetTemplate;
          const tweetReplacements = {
            ':fact': factText,
            ':code': userReferralCode,
          };
          const buttonColor = tweetButtonColors[i]
            ? tweetButtonColors[i]
            : tweetButtonColors[tweetButtonColors.length - 1];

          return (
            <StyledComponents.FactItem key={`fact-${i}`}>
              <HeaderSmall as="div" css={{ flex: 1 }} colored="white">
                {factText}
              </HeaderSmall>
              <a
                {...anchorProps}
                href={`${twitterPrefix}${makeTweet(tweetTemplate, tweetReplacements)}`}
                onClick={() => events.referrals.tweetFact.send()}
                aria-label="Tweet fact">
                <StyledTwitterLogo color={buttonColor} />
              </a>
            </StyledComponents.FactItem>
          );
        })}
      </StyledComponents.TheFactsLayout>
      <StyledComponents.Disclaimer copy={copy.disclaimer.disclaimer} />
    </Layout>
  );
};

export default ReferralsLandingPage;
