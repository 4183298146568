import React from 'react';

const StyledTwitterLogo = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.40209 19C14.9037 19 18.4586 13.6126 18.4586 8.94346C18.4586 8.78953 18.4586 8.6356 18.4513 8.48901C19.1403 7.99058 19.7413 7.36754 20.2178 6.65655C19.5874 6.93508 18.9057 7.12565 18.1874 7.21361C18.9204 6.77382 19.4775 6.08482 19.7413 5.25654C19.0597 5.65969 18.3047 5.95288 17.4984 6.11414C16.8534 5.42513 15.9372 5 14.9183 5C12.9686 5 11.3853 6.58325 11.3853 8.53298C11.3853 8.81152 11.4147 9.08272 11.4806 9.33927C8.54136 9.19267 5.93926 7.78534 4.19476 5.64503C3.89424 6.16545 3.71832 6.77382 3.71832 7.41885C3.71832 8.64293 4.34136 9.72775 5.29424 10.3581C4.71518 10.3435 4.17277 10.1822 3.69633 9.91833C3.69633 9.93298 3.69633 9.94764 3.69633 9.9623C3.69633 11.6775 4.91309 13.0995 6.53298 13.4293C6.23979 13.5099 5.9246 13.5539 5.60209 13.5539C5.37487 13.5539 5.15497 13.5319 4.93508 13.488C5.3822 14.8953 6.68691 15.9141 8.2335 15.9435C7.02408 16.889 5.49947 17.4534 3.84293 17.4534C3.55707 17.4534 3.27853 17.4387 3 17.4021C4.5466 18.4136 6.40837 19 8.40209 19Z"
      fill={color}
    />
  </svg>
);

export { StyledTwitterLogo };
