import * as React from 'react';
import styled, { css } from 'styled-components';

import { HeaderLarge, Large, Small, DisplayTiny, HeaderSmall } from '../text';
import GiftBox from './GiftBoxSvg';
import GiftingBanner from './GiftingBannerSvg';
import HeroBanner from './HeroBannerSvg';

const sharedPaddingCss = css`
  padding-left: ${({ theme }) => theme.spacing(6)};
  padding-right: ${({ theme }) => theme.spacing(6)};

  @media ${({ theme }) => theme.breakpoints.sm} {
    padding-left: ${({ theme }) => theme.spacing(3)};
    padding-right: ${({ theme }) => theme.spacing(3)};
  }

  @media ${({ theme }) => theme.breakpoints.xs} {
    padding-left: ${({ theme }) => theme.spacing(2)};
    padding-right: ${({ theme }) => theme.spacing(2)};
  }
`;

const synthwaveGradient = css`
  background: linear-gradient(111.8deg, #49fbd4 28.57%, #ffc73a 85.72%);
`;

const ModuleLayout = styled.div.attrs(props => ({
  children: (
    <React.Fragment>
      <HeaderLarge as="h2" align="center" colored="greenLight">
        {props.heading}
      </HeaderLarge>
      <div className="children">{props.children}</div>
    </React.Fragment>
  ),
}))`
  padding-top: ${({ theme }) => theme.spacing(6)};
  ${sharedPaddingCss};

  .children {
    margin: 0 auto;
    max-width: 1184px;
  }
`;

const ModuleWithBannerLayout = styled.div`
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  svg:first-of-type {
    position: absolute;
  }
`;

export const HeroLayout = styled(ModuleWithBannerLayout).attrs(props => ({
  children: (
    <React.Fragment>
      <HeroBanner />
      <div className="children">{props.children}</div>
    </React.Fragment>
  ),
}))`
  background: ${({ theme }) => theme.color.dmGrey10};
  padding-top: ${({ theme }) => theme.spacing(14)};
  padding-bottom: ${({ theme }) => theme.spacing(14)};
  ${sharedPaddingCss};

  .children {
    z-index: 1;
    display: flex;
    max-width: ${({ theme }) => theme.spacing(98)};
    align-items: center;
    flex-direction: column;
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    padding-left: ${({ theme }) => theme.spacing(5)};
    padding-right: ${({ theme }) => theme.spacing(5)};
  }
`;

export const HeroCopy = styled(DisplayTiny)`
  font-size: 54px;
  line-height: 64px;
  margin-bottom: ${({ theme }) => theme.spacing(5)};

  @media ${({ theme }) => theme.breakpoints.sm} {
    font-size: ${({ theme }) => theme.text.displayTiny.size};
    line-height: ${({ theme }) => theme.text.displayTiny.lineHeight};
  }
`;

export const HeroCodeAndButton = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  max-width: ${({ theme }) => theme.spacing(50)};
  justify-content: center;

  a {
    width: ${({ theme }) => theme.spacing(6)};
    height: ${({ theme }) => theme.spacing(6)} !important;
    padding: 0;
    margin-left: ${({ theme }) => theme.spacing(2)};
    border-radius: ${({ theme }) => theme.spacing(3)};

    svg {
      width: 21px;
      padding-top: 2px;
      margin-left: 1px;
    }
  }

  @media ${({ theme }) => theme.breakpoints.xs} {
    & > div {
      max-width: 75%;
    }
  }
`;

export const ReferralCodeField = styled(Small)`
  flex: 1;
  padding: ${({ theme }) => theme.spacing(1.5)} ${({ theme }) => theme.spacing(3)};
  display: flex;
  background: ${({ theme }) => theme.color.white};
  align-items: center;
  border-radius: ${({ theme }) => theme.spacing(3)};
  justify-content: space-between;

  & > input {
    width: 100%;
    border: none;
    caret-color: transparent;
  }

  & > button:hover {
    color: ${({ theme }) => theme.color.greenLight};
  }

  @media ${({ theme }) => theme.breakpoints.xs} {
    max-width: 75%;

    & > input {
      display: block;
      overflow: hidden;
      max-width: 80%;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

export const Band = styled.div`
  height: ${({ theme }) => theme.spacing(1)};
  ${synthwaveGradient};
`;

export const ThePerksLayout = styled(ModuleLayout)`
  padding-bottom: ${({ theme }) => theme.spacing(17)};

  .children {
    display: flex;
  }

  ${HeaderLarge} {
    padding-bottom: ${({ theme }) => theme.spacing(11)};
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    padding-bottom: ${({ theme }) => theme.spacing(11)};

    .children {
      flex-direction: column;
    }

    ${HeaderLarge} {
      padding-bottom: ${({ theme }) => theme.spacing(8)};
    }
  }
`;

export const PerkItemLayout = styled.div`
  width: 50%;

  & > * + * {
    margin-top: ${({ theme }) => theme.spacing(3)};
  }

  &:first-of-type {
    border-right: 2px solid ${({ theme }) => theme.color.greenLight};
    padding-right: ${({ theme }) => theme.spacing(7.5)};
  }

  &:last-of-type {
    padding-left: ${({ theme }) => theme.spacing(7.5)};
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    width: 100%;
    margin: 0 auto;
    max-width: ${({ theme }) => theme.spacing(70)};

    &:first-of-type {
      border-right: none;
      padding-right: 0;
      border-bottom: 2px solid ${({ theme }) => theme.color.greenLight};
      padding-bottom: ${({ theme }) => theme.spacing(8)};
    }

    &:last-of-type {
      padding-top: ${({ theme }) => theme.spacing(8)};
      padding-left: 0;
    }

    ${DisplayTiny} {
      font-size: ${({ theme }) => theme.text.headerLarge.size};
      line-height: ${({ theme }) => theme.text.headerLarge.lineHeight};
    }
  }
`;

export const HowItWorksSteps = styled(Large)`
  ol {
    list-style: auto;
    padding-top: ${({ theme }) => theme.spacing(1)};
    padding-left: ${({ theme }) => theme.spacing(2.5)};
  }

  ol > * + * {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }

  li {
    padding-left: ${({ theme }) => theme.spacing(2.5)};
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    font-size: ${({ theme }) => theme.text.medium.size};
    line-height: ${({ theme }) => theme.text.medium.lineHeight};
  }
`;

export const NameGiftingNote = styled(ModuleWithBannerLayout).attrs(props => ({
  children: (
    <React.Fragment>
      <GiftingBanner />
      <GiftBox />
      <Small align="center" colored="grey20">
        {props.copy}
      </Small>
    </React.Fragment>
  ),
}))`
  background: ${({ theme }) => theme.color.dmGrey20};
  padding-top: ${({ theme }) => theme.spacing(3)};
  padding-bottom: ${({ theme }) => theme.spacing(3)};

  ${Small} {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }
`;

export const TheFactsLayout = styled(ModuleLayout)`
  background: ${({ theme }) => theme.color.dmGrey10};
  padding-bottom: ${({ theme }) => theme.spacing(13)};

  .children {
    overflow: hidden;
    ${synthwaveGradient};
  }

  ${HeaderLarge} {
    padding-bottom: ${({ theme }) => theme.spacing(8)};
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    ${HeaderLarge} {
      padding-bottom: ${({ theme }) => theme.spacing(4)};
    }
  }
`;

export const FactItem = styled.div`
  height: ${({ theme }) => theme.spacing(13)};
  display: flex;
  background: ${({ theme }) => theme.color.dmGrey10};
  align-items: center;
  margin-bottom: 1px;

  a {
    width: max-content;
    height: max-content;
    margin-left: ${({ theme }) => theme.spacing(3)};
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    ${HeaderSmall} {
      font-size: ${({ theme }) => theme.text.medium.size};
      line-height: ${({ theme }) => theme.text.medium.lineHeight};
    }
  }
`;

export const Disclaimer = styled.div.attrs(props => ({
  children: (
    <Small align="center" colored="grey20">
      {props.copy}
    </Small>
  ),
}))`
  display: flex;
  background: ${({ theme }) => theme.color.dmGrey20};
  padding-top: ${({ theme }) => theme.spacing(5)};
  padding-bottom: ${({ theme }) => theme.spacing(5)};
  justify-content: center;
  ${sharedPaddingCss};

  ${Small} {
    max-width: ${({ theme }) => theme.spacing(98)};
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    ${Small} {
      max-width: ${({ theme }) => theme.spacing(84)};
    }
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    ${Small} {
      width: 100%;
      max-width: ${({ theme }) => theme.spacing(65)};
    }
  }
`;
